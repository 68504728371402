import React from "react"
import { Row, Col } from "react-flexbox-grid"

import Modal from "../../helper/react-awesome-modal"
import SourceEmitter from "../../helper/emitter"

import closeImg from "../../assets/images/close-btn.png"

import "./index.scss"

let interstitialSubscription = null
let awayURL = ""
const currentMaxWidth = 960

class Interstitial extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: false,
      interstitialVisible: false,
      width: "",
    }

    this.closeInterstitial = this.closeInterstitial.bind(this)
  }
  openInterstitialLink(link) {
    this.setState({
      interstitialVisible: false,
    })
    setTimeout(() => {
      window.open(link)
    }, 100)
  }

  openInterstitial() {
    this.setState({
      interstitialVisible: true,
    })
    if (typeof window !== "undefined") {
      const updateWidth =
        window.innerWidth > currentMaxWidth
          ? Math.floor(0.8 * currentMaxWidth)
          : Math.floor(0.8 * window.innerWidth)
      this.setState({ width: `${updateWidth}px` })
    }
  }

  closeInterstitial() {
    this.setState({
      interstitialVisible: false,
    })
    this.setState({
      width: "",
    })
  }

  // componentWillMount() {
  //   // For External Interstitial
  //   interstitialSubscription = SourceEmitter.addListener(
  //     "onExternalLinkEvent",
  //     data => {
  //       awayURL = data
  //       this.openInterstitial()
  //     }
  //   )
  // }

  componentDidMount() {
    interstitialSubscription = SourceEmitter.addListener(
      "onExternalLinkEvent",
      data => {
        awayURL = data
        this.openInterstitial()
      }
    )
  }

  componentWillUnmount() {
    interstitialSubscription && interstitialSubscription.remove()
  }

  render() {
    return (
      this.state.interstitialVisible && (
        <Modal
          visible={this.state.interstitialVisible}
          effect="fadeInUp"
          onClickAway={() => this.closeInterstitial()}
          width={this.state.width}
        >
          <div className="interstitial-modal">
            <Row end="xs">
              <Col xs={2}>
                <img
                  src={closeImg}
                  className="close-btn"
                  onClick={this.closeInterstitial}
                  alt="Close Button"
                />
              </Col>
            </Row>

            <Row center="xs">
              <Col xs={12} md={10}>
                <div className="interstitial-modal">
                  <p>
                    By clicking on the button below, you acknowledge that you
                    are leaving the Harmony Biosciences website.
                  </p>
                </div>
              </Col>
            </Row>

            <Row end="xs">
              <Col xs={6} className="text-align-right">
                <div className="interstitial-modal">
                  <button onClick={() => this.openInterstitialLink(awayURL)}>
                    CONTINUE
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      )
    )
  }
}

export default Interstitial
