import React, { Component } from "react"
import { Row, Col } from "react-flexbox-grid"
import "./index.scss"
import Hero from "../../components/Hero"
import Layout from "../../components/Layout"
import ContentBlock from "../../components/ContentBlock"
import Quote from "../../components/Quote"
import HelmetWrapper from "../../components/HelmetWrapper"
import ExternalLink from "../../components/ExternalLink"
import Interstitial from "../../components/ExternalInterstitial/Interstitial"
import pipelineImg from "../../assets/images/pipeline-chart-lg.png"
import pipelineImgLg from "../../assets/images/pipeline-jun24.png"
import circleImg1 from "../../assets/images/sm_circle_Polistat.png"
import bioCircleImg1 from "../../assets/images/bio_sm_circle_JefferyDayno.webp"
import smCircleImg1 from "../../assets/images/sm_circle_TwoScientists.png"
import nextGenImg from "../../assets/images/harmony-icon-2@3x.png"
import hbsImg from "../../assets/images/harmony-icon-4@3x.png"
import smCircleImg2 from "../../assets/images/sm_circle_OurPipeline.png"
import headerHero from "../../assets/images/hero_science2.webp"
import tempoLogo from "../../images/TempoLogo.png";
import reconnectLogo from "../../images/ReconnectLogo.png"
import smCircleHBS from "../../assets/images/HBS-102-Icon-FNL.webp"
import { Link } from "gatsby"

const pageTitle = "Science | Harmony Biosciences"
const nct1Tracking = {
  category: "ExternalLink",
  action: "click",
  label: "NCT04257929",
}
const nct2Tracking = {
  category: "ExternalLink",
  action: "click",
  label: "NCT04886518",
}
const pageDescription =
  "We are translating science into therapeutic possibilities for patients with unmet medical needs. Find our pipeline, clinical trials and development portfolio."
const pageKeywords = "Pitolisant - 880"

class science extends Component {
  constructor() {
    super()
  }

  peaceLinkClick = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "thepeacenarcolepsyprogram.com"
      )
    }
    window.open("https://thepeacenarcolepsyprogram.com/", "_blank")
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200062/Aug 2022">
        <div id="science">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/science"
          />
          <Hero col={7} bg={headerHero}>
            <h1>
              Translating Innovative Science <br /> into Therapeutic
              Possibilities
            </h1>
          </Hero>

          <ContentBlock color="orange" id="pipeline-chart">
            <Row>
              <Col xs={12} md={12}>
                <img
                  src={pipelineImgLg}
                  style={{ paddingTop: "0px" }}
                  alt="Harmony Biosciences pipeline"
                  className="img-pipeline center"
                />
              </Col>
            </Row>
          </ContentBlock>

          <ContentBlock color="blue">
            <Row>
              <Col xs={12} md={10}>
                <h2
                  style={{
                    paddingTop: 0,
                    fontSize: "23px",
                    paddingBottom: "20px",
                  }}
                >
                  CLINICAL TRIALS
                </h2>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className="trial-box">
                  <div className="title-box">
                    <div className="title-header-container">
                      <p>
                        <Link to="/patients/research-focus-areas/#prader-willi">
                          Prader-Willi syndrome
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="information-box">
                    <div className="logo-image">
                      <img src={tempoLogo} />
                    </div>
                    <p className="tempoP">
                      A Phase 3, Randomized, Double-Blind, Placebo-Controlled,
                      Efficacy and Safety Study of Pitolisant Followed by an
                      Open-Label Extension in Patients With Prader-Willi
                      Syndrome
                    </p>

                    <p className="">
                      <a href="https://www.tempopwsstudy.com/" target="_blank">
                        <button aria-label="Learn More">LEARN MORE</button>
                      </a>
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="trial-box">
                  <div className="title-box">
                    <div className="title-header-container">
                      <p>
                        <Link to="/patients/research-focus-areas/#fragile-x">
                          Fragile X syndrome
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="information-box">
                    <div className="logo-image reconnect-image">
                      <img src={reconnectLogo} />
                    </div>

                    <p className="reconnectP">
                      A Phase 3, Randomized, Double-Blind, Placebo-Controlled,
                      Efficacy and Safety Study of ZYN002 in Children,
                      Adolescents, and Young Adults With Fragile X Syndrome
                    </p>
                    <p className="">
                      <a href="https://fragilexhelp.com/" target="_blank">
                        <button aria-label="Learn More">LEARN MORE</button>
                      </a>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </ContentBlock>
          <div
            className="content-wrapper-blue"
            style={{ background: "#ebf1f8" }}
          >
            <div style={{ padding: "10px 30px" }}>
              <Row>
                <Col xs={12} md={10}>
                  <h2 style={{ fontSize: "23px", paddingBottom: "0" }}>
                    DEVELOPMENT PORTFOLIO
                  </h2>
                </Col>
              </Row>
            </div>

            <ContentBlock color="orange">
              <Row>
                <Col xs={12} md={2}>
                  <Row center="xs">
                    <Col>
                      <img src={circleImg1} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={10}>
                  <h2>PITOLISANT</h2>
                  <p>
                    Pitolisant is a histamine-3 receptor (H<sub>3</sub>R)
                    antagonist/inverse agonist, which was designed and developed
                    by Bioprojet Societe Civile de Recherche (Bioprojet), and
                    was approved in Europe by the European Medicines Agency in
                    2016. Harmony has an exclusive license from Bioprojet to
                    develop, manufacture, and commercialize pitolisant in the
                    United States.
                  </p>
                  <p>
                    Pitolisant, initially developed for treatment of excessive
                    daytime sleepiness and cataplexy in patients with
                    narcolepsy, is also being evaluated in patients with
                    idiopathic hypersomnia, Prader-Willi syndrome, and myotonic
                    dystrophy type 1.
                  </p>
                </Col>
              </Row>
            </ContentBlock>

            <ContentBlock color="green">
              <Row>
                <Col xs={12} md={2}>
                  <Row center="xs">
                    <Col>
                      <img src={nextGenImg} className="mmw147" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={10}>
                  <h2>NEXT-GENERATION PITOLISANT FORMULATIONS</h2>
                  <p>
                    Two next-generation formulations of pitolisant tablets (NG1
                    and NG2) represent modifications to the current pitolisant
                    tablet formulation.
                  </p>
                  <p>
                    NG1 is being developed as a delayed-release formulation. NG2
                    is a reformulation with excipient modification in which the
                    pharmacokinetic profile and different and higher dosage
                    strengths will be evaluated.
                  </p>
                </Col>
              </Row>
            </ContentBlock>

            <ContentBlock color="blue">
              <Row>
                <Col xs={12} md={2}>
                  <Row center="xs">
                    <Col>
                      <img src={smCircleHBS} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={10}>
                  <h2>ZYN002</h2>
                  <p>
                    ZYN002 is an investigational drug product in development for
                    the potential treatment of behavioral symptoms associated
                    with Fragile X syndrome (FXS) and 22q11.2 deletion syndrome
                    (22q). ZYN002 is a pharmaceutically manufactured synthetic
                    cannabidiol formulated as a patent-protected
                    permeation-enhanced gel for transdermal delivery through the
                    skin and into the circulatory system. ZYN002 does not
                    contain tetrahydrocannabinol (THC), the compound that causes
                    the euphoric effect of cannabis.
                  </p>
                </Col>
              </Row>
            </ContentBlock>
            <ContentBlock color="orange">
              <Row>
                <Col xs={12} md={2}>
                  <Row center="xs">
                    <Col>
                      <img src={hbsImg} className="mmw147" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={10}>
                  <h2>HBS-102</h2>
                  <p>
                    HBS-102, an investigational compound, is a
                    melanin-concentrating hormone receptor 1 (MCHR1) antagonist
                    that has the potential to offer a novel approach to the
                    treatment of a variety of rare neurological diseases.
                    Melanin-concentrating hormone neurons are located in the
                    hypothalamus and are involved in several different
                    functions, including feeding behaviors, energy balance and
                    control of metabolic functions, rapid eye movement (REM)
                    sleep, and mood and behavioral processing, among others.
                  </p>
                </Col>
              </Row>
            </ContentBlock>
          </div>

          <Interstitial />
        </div>
      </Layout>
    )
  }
}

export default science
